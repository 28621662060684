.ArtistPage {
  background: #eee;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.ArtistPage h1 {
  display: block;
  font-size: 48px;
  text-align: center;
  margin: auto;
  margin: 32px 48px 32px 48px;
  
}
 .ImgWrapper {
  margin: auto;
  max-height: 500px;
  max-width: 500px;
  margin-bottom: 28px;
  
}
 .ImgWrapper img {
  display: block;
  object-fit: contain;
  height: 100%;
  width: 100%;
  margin: auto;
}
.about {
  padding: 5px;
  margin-bottom: 10px;
}
.about h2 {
  text-align: center;
  margin-bottom: 18px;
}
.about pre {
  margin: 0px 5px 0px 5px;
  columns: 2;
  column-gap: 2em;
  white-space: pre-line;
  text-align: justify;
  font-size: 1em;
  line-height: 2em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;

  
  
}
@media (max-width:885px){
  .about pre{
    columns:1;
    text-align:left;
  }
}