.SearchForm{
    margin: auto auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
}
.SearchForm label{
    display: block;
    text-align: center;
    font-size: 1.4em;
}
.SearchForm input{
    background-color: #eee;
    padding: 5px;
    margin-top: 5px;
    font-size: 1.2em;
    color:black;
    
}
.SearchForm input:focus{
    color:black;
    outline: none;
    background-color: rgb(111, 116, 91);
    background-color: #f0f34fc5;
}
.SearchForm button{
    display: block;
    background-color: rgb(211, 120, 68);
    background:  linear-gradient(-315deg, var(--colors-gradient));
    color: black;
    padding:5px;
    margin-top: 5px;
    font-size: 1em;
    font-weight:550;
}
.SearchForm button:active{
    outline: none;
    background-color: slategray;
}