.LyricPage {
  background: #eee;
  padding: 0px 0px 24px 0px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: repeat(3, auto) 1fr;
  row-gap: 24px;
}
.LyricPage h1 {
  font-size: 48px;
  text-align: center;
  margin: auto; 
   margin: 24px 48px 48px 48px;
  grid-column: 1/-1;
  column-gap: 24px;
}

.LyricPageInfoBox {
  margin: 5px;
  max-width: 450px;
}
.LyricPageInfoBox img {
  display: block;
  object-fit: contain;
  width: 100%;
  margin: auto;
  margin-bottom: 24px;
}
.LyricPageInfoBox ul {
  list-style: none;
  margin: none;
  padding: 0;
  border-collapse: collapse;
  border: 1px solid black;
  border-bottom: none;
}
.LyricPageInfoBox li {
  padding: 2px;
  border-bottom: 1px solid black;
  color: #444;
  text-overflow: clip;
}
.LyricPageInfoBox li span {
  color: #000;
  font-weight: 500;
}
.LyricPageInfoBox button {
  display: block;
  min-height: 2em;
  width: 100%;
  background-image: linear-gradient(-315deg, var(--colors-gradient));
  font-weight: bold;
  font-size: 1em;
  text-align: center;
}

/* YOUTUBE DISPLAY */
.YoutubeContainer{
  grid-column: 2;
  grid-row: 3 / 4;
  width: 100%;
  min-width:100px;
  min-height:240px;
  max-height:400px;
}
.YoutubeContainer iframe{
  width: 100%;  
  height: 100%;
}

/* ALBUM */
.AlbumContainer{
  grid-column: 2;
  grid-row: 4 / 5;
  
}
.AlbumSubText{
  display: inline-block;
  font-size: 16px;
  margin-bottom: 12px;
  padding: 0 24px;
}
.AlbumTitle{
  text-align: center;
  font-size: 32px;
  margin-bottom: 14px;
}
.AlbumList{
  list-style: none;
  margin: 0px 24px 0px 64px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  justify-items: left;
  row-gap:20px;
  column-gap: 32px;

}
.AlbumItem{
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  
}
.AlbumItem a{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
.AlbumItem a:hover{
  color: #999;
  
}

/* LYRICS  */
.LyricsBox {
  padding: 5px;
  grid-column: 1;
  grid-row: 2/ 6;
}
.LyricsBox h2 {
  text-align: center;
  margin: 10px auto;
}

.LyricsBox button {
  display: block;
  background-image: linear-gradient(-315deg, var(--colors-gradient));
  padding: 0 10px;
  margin: auto;
  margin-bottom: 2em;
  text-align: center;
  font-weight: bold;
  min-height: 2em;
  font-size: 1em;
}

.LyricsBox pre {
  white-space: pre-line;
  font-size: 1em;
  line-height: 2em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0px 10px 0px 5px;
}

@media (max-width:885px){
  .LyricPage{
    grid-template-columns: 1fr;
  }
  .LyricPage h1 {
    font-size: 36px;
  }
  .LyricsBox, .LyricPageInfoBox, .YoutubeContainer, .AlbumContainer {
    grid-column: auto;
    grid-row: auto;
    margin: 0 auto;
  }
  .LyricPageInfoBox{
    margin-bottom: 24px;
  }
  .YoutubeContainer{
    width: 80%;
  }
  .AlbumContainer{
    grid-row: -1;
    
  }
  .AlbumList{
    grid-template-columns: 1fr;

  }
  .AlbumItem{
    font-size: 24px;
  }
  
}