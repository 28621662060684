@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600&family=Quicksand:wght@400;500;600;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body 
{
  margin: 0;
  background: #ccc;
  color: rgb(0, 0, 0);
  /* font-family: 'Josefin Sans', sans-serif; */
  font-family: 'Quicksand', sans-serif;
}
.Link{
  text-decoration: none;
  color:inherit;
}
