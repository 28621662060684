:root{ 
    --myYellow: #fcff57;
    --colors-gradient: #ebce2f, #55d289;
}
.MainLayout{
    display: flex;
    flex-direction:column;
    gap: 10px;
    width: 80%;
    margin: auto;
    color:black;
    overflow:hidden;
    min-height:100vh;
    
}
.Layout .Footer{
    background-image: linear-gradient(-315deg, var(--colors-gradient));
    width: 100%;
    height: 2em;
    margin-top: 50px;  
}
@media (max-width:885px){
    .MainLayout{
        width: 90%;
    
    }   
}
@media (max-width:610px){
    .MainLayout{
        width: 95%;
    
    }   
}