.nav-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding:0;
    margin: 0px 0px 50px 0px;
    background-color: #f2cf07;
    background-image: linear-gradient(-315deg, var(--colors-gradient));

    overflow:hidden;

}
.main-nav-bar {
    width:80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Search{
    font-size: 1.2em;
    text-decoration: none;
    color:black;
    flex-grow: 0.25;
    padding-left: 100px;
}
.SearchIcon{
    display: none;
    width: 24px;
    height: 24px;
}
.title{
     margin: 0;
     display: inline;
     font-size: 2em;
     flex-grow: 1;
     text-align: center;
     font-weight: 600;
}
.About{
    font-size: 1.2em;
    text-decoration: none;
    color:black;
    flex-grow: 0.25;
    text-align: right;
    padding-right:100px;
}
.titleContainer{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
@media (max-width:885px){
    .main-nav-bar{
        width:90%;
    }
    .main-nav-bar .Search{
        padding-left:0;
    }
    .main-nav-bar .About{
        padding-right:0;
    }
    .Search span{
        display: none;
    }
    .SearchIcon{
        display: block;
    }
}