@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600&family=Quicksand:wght@400;500;600;700&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body 
{
  margin: 0;
  background: #ccc;
  color: rgb(0, 0, 0);
  /* font-family: 'Josefin Sans', sans-serif; */
  font-family: 'Quicksand', sans-serif;
}
.Link{
  text-decoration: none;
  color:inherit;
}


:root{ 
    --myYellow: #fcff57;
    --colors-gradient: #ebce2f, #55d289;
}
.Layout_MainLayout__2DX-V{
    display: flex;
    flex-direction:column;
    grid-gap: 10px;
    gap: 10px;
    width: 80%;
    margin: auto;
    color:black;
    overflow:hidden;
    min-height:100vh;
    
}
.Layout_Layout__1iVNg .Layout_Footer__10e0Q{
    background-image: linear-gradient(-315deg, #ebce2f, #55d289);
    background-image: linear-gradient(-315deg, var(--colors-gradient));
    width: 100%;
    height: 2em;
    margin-top: 50px;  
}
@media (max-width:885px){
    .Layout_MainLayout__2DX-V{
        width: 90%;
    
    }   
}
@media (max-width:610px){
    .Layout_MainLayout__2DX-V{
        width: 95%;
    
    }   
}
.nav-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding:0;
    margin: 0px 0px 50px 0px;
    background-color: #f2cf07;
    background-image: linear-gradient(-315deg, var(--colors-gradient));

    overflow:hidden;

}
.main-nav-bar {
    width:80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Search{
    font-size: 1.2em;
    text-decoration: none;
    color:black;
    flex-grow: 0.25;
    padding-left: 100px;
}
.SearchIcon{
    display: none;
    width: 24px;
    height: 24px;
}
.title{
     margin: 0;
     display: inline;
     font-size: 2em;
     flex-grow: 1;
     text-align: center;
     font-weight: 600;
}
.About{
    font-size: 1.2em;
    text-decoration: none;
    color:black;
    flex-grow: 0.25;
    text-align: right;
    padding-right:100px;
}
.titleContainer{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
@media (max-width:885px){
    .main-nav-bar{
        width:90%;
    }
    .main-nav-bar .Search{
        padding-left:0;
    }
    .main-nav-bar .About{
        padding-right:0;
    }
    .Search span{
        display: none;
    }
    .SearchIcon{
        display: block;
    }
}
.logoContainer{
  display: inline-block;
  width: 2rem;
  height: 2rem;
}
.HitPreview_HitPreview__1H-f9{
    width: 150px;
    border: 1px solid black;
    background: #ddd;
    padding: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 120px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: space-between;
    align-content: space-between;
    overflow: hidden;
}
.HitPreview_hitPreviewTitle__g3hka{
    font-weight: 600;
    word-wrap: break-word
}
.HitPreview_hitPreviewArtist__qi5OC{
    font-weight: 400;
    word-wrap: break-word;
}

.HitPreview_HitPreview__1H-f9 button{
    margin: auto;
    margin-top: 5px;
    width: 100%;
    display:block;
    background-image: linear-gradient(-315deg, var(--colors-gradient));
    font-weight:bold;
    justify-self: flex-end;
    align-self: flex-end;
    padding: 5px;
    
}
.HitPreview_HitPreview__1H-f9 button:active{
    outline: none;
}
@media (max-width:885px){
    .HitPreview_HitPreview__1H-f9{
        width: 30%;
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content;
    }   
}
@media (max-width:610px){
    .HitPreview_HitPreview__1H-f9{
        width:auto;
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content;
    }   
}
.HitsList_HitList__QwtRY{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
    flex-wrap:wrap;
    grid-column-gap: 5%;
    column-gap: 5%;
    grid-row-gap: 70px;
    row-gap: 70px;
    margin-bottom: 24px;
}
.HitsList_LoaderContainer__3XtRV{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media (max-width:610px){
    .HitsList_HitList__QwtRY{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items:stretch;
    }

}

/* Spinner */

.cssload-pgloading:after {
		content: "";
		z-index: -1;
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
}
.cssload-pgloading .cssload-loadingwrap {position:absolute;top:45%;bottom:45%;left:25%;right:25%;}
.cssload-pgloading .cssload-bokeh {
		font-size: 97px;
		width: 1em;
		height: 1em;
		position: relative;
		margin: 0 auto;
		list-style: none;
		padding:0;
		border-radius: 50%;
		-o-border-radius: 50%;
		-ms-border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
}

.cssload-pgloading .cssload-bokeh li {
		position: absolute;
		width: .2em;
		height: .2em;
		border-radius: 50%;
		-o-border-radius: 50%;
		-ms-border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
}

.cssload-pgloading .cssload-bokeh li:nth-child(1) {
		left: 50%;
		top: 0;
		margin: 0 0 0 -.1em;
		background: rgb(0,193,118);
		transform-origin: 50% 250%;
		-o-transform-origin: 50% 250%;
		-ms-transform-origin: 50% 250%;
		-webkit-transform-origin: 50% 250%;
		-moz-transform-origin: 50% 250%;
		animation: 
				cssload-rota 1.3s linear infinite,
				cssload-opa 4.22s ease-in-out infinite alternate;
		-o-animation: 
				cssload-rota 1.3s linear infinite,
				cssload-opa 4.22s ease-in-out infinite alternate;
		-ms-animation: 
				cssload-rota 1.3s linear infinite,
				cssload-opa 4.22s ease-in-out infinite alternate;
		-webkit-animation: 
				cssload-rota 1.3s linear infinite,
				cssload-opa 4.22s ease-in-out infinite alternate;
		-moz-animation: 
				cssload-rota 1.3s linear infinite,
				cssload-opa 4.22s ease-in-out infinite alternate;
}

.cssload-pgloading .cssload-bokeh li:nth-child(2) {
		top: 50%; 
		right: 0;
		margin: -.1em 0 0 0;
		background: rgb(255,0,60);
		transform-origin: -150% 50%;
		-o-transform-origin: -150% 50%;
		-ms-transform-origin: -150% 50%;
		-webkit-transform-origin: -150% 50%;
		-moz-transform-origin: -150% 50%;
		animation: 
				cssload-rota 2.14s linear infinite,
				cssload-opa 4.93s ease-in-out infinite alternate;
		-o-animation: 
				cssload-rota 2.14s linear infinite,
				cssload-opa 4.93s ease-in-out infinite alternate;
		-ms-animation: 
				cssload-rota 2.14s linear infinite,
				cssload-opa 4.93s ease-in-out infinite alternate;
		-webkit-animation: 
				cssload-rota 2.14s linear infinite,
				cssload-opa 4.93s ease-in-out infinite alternate;
		-moz-animation: 
				cssload-rota 2.14s linear infinite,
				cssload-opa 4.93s ease-in-out infinite alternate;
}

.cssload-pgloading .cssload-bokeh li:nth-child(3) {
		left: 50%; 
		bottom: 0;
		margin: 0 0 0 -.1em;
		background: rgb(250,190,40);
		transform-origin: 50% -150%;
		-o-transform-origin: 50% -150%;
		-ms-transform-origin: 50% -150%;
		-webkit-transform-origin: 50% -150%;
		-moz-transform-origin: 50% -150%;
		animation: 
				cssload-rota 1.67s linear infinite,
				cssload-opa 5.89s ease-in-out infinite alternate;
		-o-animation: 
				cssload-rota 1.67s linear infinite,
				cssload-opa 5.89s ease-in-out infinite alternate;
		-ms-animation: 
				cssload-rota 1.67s linear infinite,
				cssload-opa 5.89s ease-in-out infinite alternate;
		-webkit-animation: 
				cssload-rota 1.67s linear infinite,
				cssload-opa 5.89s ease-in-out infinite alternate;
		-moz-animation: 
				cssload-rota 1.67s linear infinite,
				cssload-opa 5.89s ease-in-out infinite alternate;
}

.cssload-pgloading .cssload-bokeh li:nth-child(4) {
		top: 50%; 
		left: 0;
		margin: -.1em 0 0 0;
		background: rgb(136,193,0);
		transform-origin: 250% 50%;
		-o-transform-origin: 250% 50%;
		-ms-transform-origin: 250% 50%;
		-webkit-transform-origin: 250% 50%;
		-moz-transform-origin: 250% 50%;
		animation: 
				cssload-rota 1.98s linear infinite,
				cssload-opa 6.04s ease-in-out infinite alternate;
		-o-animation: 
				cssload-rota 1.98s linear infinite,
				cssload-opa 6.04s ease-in-out infinite alternate;
		-ms-animation: 
				cssload-rota 1.98s linear infinite,
				cssload-opa 6.04s ease-in-out infinite alternate;
		-webkit-animation: 
				cssload-rota 1.98s linear infinite,
				cssload-opa 6.04s ease-in-out infinite alternate;
		-moz-animation: 
				cssload-rota 1.98s linear infinite,
				cssload-opa 6.04s ease-in-out infinite alternate;
}







@keyframes cssload-rota {
		from { }
		to { transform: rotate(360deg); }
}

@keyframes cssload-opa {
		0% { }
		12.0% { opacity: 0.80; }
		19.5% { opacity: 0.88; }
		37.2% { opacity: 0.64; }
		40.5% { opacity: 0.52; }
		52.7% { opacity: 0.69; }
		60.2% { opacity: 0.60; }
		66.6% { opacity: 0.52; }
		70.0% { opacity: 0.63; }
		79.9% { opacity: 0.60; }
		84.2% { opacity: 0.75; }
		91.0% { opacity: 0.87; }
}
.SearchForm_SearchForm__ADz5R{
    margin: auto auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
}
.SearchForm_SearchForm__ADz5R label{
    display: block;
    text-align: center;
    font-size: 1.4em;
}
.SearchForm_SearchForm__ADz5R input{
    background-color: #eee;
    padding: 5px;
    margin-top: 5px;
    font-size: 1.2em;
    color:black;
    
}
.SearchForm_SearchForm__ADz5R input:focus{
    color:black;
    outline: none;
    background-color: rgb(111, 116, 91);
    background-color: #f0f34fc5;
}
.SearchForm_SearchForm__ADz5R button{
    display: block;
    background-color: rgb(211, 120, 68);
    background:  linear-gradient(-315deg, var(--colors-gradient));
    color: black;
    padding:5px;
    margin-top: 5px;
    font-size: 1em;
    font-weight:550;
}
.SearchForm_SearchForm__ADz5R button:active{
    outline: none;
    background-color: slategray;
}

.LyricPage_LyricPage__3KvJJ {
  background: #eee;
  padding: 0px 0px 24px 0px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: repeat(3, auto) 1fr;
  grid-row-gap: 24px;
  row-gap: 24px;
}
.LyricPage_LyricPage__3KvJJ h1 {
  font-size: 48px;
  text-align: center;
  margin: auto; 
   margin: 24px 48px 48px 48px;
  grid-column: 1/-1;
  grid-column-gap: 24px;
  column-gap: 24px;
}

.LyricPage_LyricPageInfoBox__30k08 {
  margin: 5px;
  max-width: 450px;
}
.LyricPage_LyricPageInfoBox__30k08 img {
  display: block;
  object-fit: contain;
  width: 100%;
  margin: auto;
  margin-bottom: 24px;
}
.LyricPage_LyricPageInfoBox__30k08 ul {
  list-style: none;
  margin: none;
  padding: 0;
  border-collapse: collapse;
  border: 1px solid black;
  border-bottom: none;
}
.LyricPage_LyricPageInfoBox__30k08 li {
  padding: 2px;
  border-bottom: 1px solid black;
  color: #444;
  text-overflow: clip;
}
.LyricPage_LyricPageInfoBox__30k08 li span {
  color: #000;
  font-weight: 500;
}
.LyricPage_LyricPageInfoBox__30k08 button {
  display: block;
  min-height: 2em;
  width: 100%;
  background-image: linear-gradient(-315deg, var(--colors-gradient));
  font-weight: bold;
  font-size: 1em;
  text-align: center;
}

/* YOUTUBE DISPLAY */
.LyricPage_YoutubeContainer__lfZs6{
  grid-column: 2;
  grid-row: 3 / 4;
  width: 100%;
  min-width:100px;
  min-height:240px;
  max-height:400px;
}
.LyricPage_YoutubeContainer__lfZs6 iframe{
  width: 100%;  
  height: 100%;
}

/* ALBUM */
.LyricPage_AlbumContainer__1sdbP{
  grid-column: 2;
  grid-row: 4 / 5;
  
}
.LyricPage_AlbumSubText__30FPC{
  display: inline-block;
  font-size: 16px;
  margin-bottom: 12px;
  padding: 0 24px;
}
.LyricPage_AlbumTitle__3xOLu{
  text-align: center;
  font-size: 32px;
  margin-bottom: 14px;
}
.LyricPage_AlbumList__2oDA1{
  list-style: none;
  margin: 0px 24px 0px 64px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  justify-items: left;
  grid-row-gap:20px;
  row-gap:20px;
  grid-column-gap: 32px;
  column-gap: 32px;

}
.LyricPage_AlbumItem__1fhL2{
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  
}
.LyricPage_AlbumItem__1fhL2 a{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
.LyricPage_AlbumItem__1fhL2 a:hover{
  color: #999;
  
}

/* LYRICS  */
.LyricPage_LyricsBox__P14oA {
  padding: 5px;
  grid-column: 1;
  grid-row: 2/ 6;
}
.LyricPage_LyricsBox__P14oA h2 {
  text-align: center;
  margin: 10px auto;
}

.LyricPage_LyricsBox__P14oA button {
  display: block;
  background-image: linear-gradient(-315deg, var(--colors-gradient));
  padding: 0 10px;
  margin: auto;
  margin-bottom: 2em;
  text-align: center;
  font-weight: bold;
  min-height: 2em;
  font-size: 1em;
}

.LyricPage_LyricsBox__P14oA pre {
  white-space: pre-line;
  font-size: 1em;
  line-height: 2em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0px 10px 0px 5px;
}

@media (max-width:885px){
  .LyricPage_LyricPage__3KvJJ{
    grid-template-columns: 1fr;
  }
  .LyricPage_LyricPage__3KvJJ h1 {
    font-size: 36px;
  }
  .LyricPage_LyricsBox__P14oA, .LyricPage_LyricPageInfoBox__30k08, .LyricPage_YoutubeContainer__lfZs6, .LyricPage_AlbumContainer__1sdbP {
    grid-column: auto;
    grid-row: auto;
    margin: 0 auto;
  }
  .LyricPage_LyricPageInfoBox__30k08{
    margin-bottom: 24px;
  }
  .LyricPage_YoutubeContainer__lfZs6{
    width: 80%;
  }
  .LyricPage_AlbumContainer__1sdbP{
    grid-row: -1;
    
  }
  .LyricPage_AlbumList__2oDA1{
    grid-template-columns: 1fr;

  }
  .LyricPage_AlbumItem__1fhL2{
    font-size: 24px;
  }
  
}
.About_About__1dBX1{
    margin: 0 auto;
    width: 60%;
    
}
.About_About__1dBX1 h1{
    text-align: center;
    margin-bottom: 0.7em;
}
.About_About__1dBX1 p{
    text-align: justify;
    line-height: 1.5em;
    margin-bottom: 1em;
}
@media (max-width:610px){
    .About_About__1dBX1{
        width: 80%;
    
    }   
}
.ArtistPage_ArtistPage__35ptE {
  background: #eee;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.ArtistPage_ArtistPage__35ptE h1 {
  display: block;
  font-size: 48px;
  text-align: center;
  margin: auto;
  margin: 32px 48px 32px 48px;
  
}
 .ArtistPage_ImgWrapper__2VcZ6 {
  margin: auto;
  max-height: 500px;
  max-width: 500px;
  margin-bottom: 28px;
  
}
 .ArtistPage_ImgWrapper__2VcZ6 img {
  display: block;
  object-fit: contain;
  height: 100%;
  width: 100%;
  margin: auto;
}
.ArtistPage_about__AYCoP {
  padding: 5px;
  margin-bottom: 10px;
}
.ArtistPage_about__AYCoP h2 {
  text-align: center;
  margin-bottom: 18px;
}
.ArtistPage_about__AYCoP pre {
  margin: 0px 5px 0px 5px;
  columns: 2;
  grid-column-gap: 2em;
  column-gap: 2em;
  white-space: pre-line;
  text-align: justify;
  font-size: 1em;
  line-height: 2em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;

  
  
}
@media (max-width:885px){
  .ArtistPage_about__AYCoP pre{
    columns:1;
    text-align:left;
  }
}
