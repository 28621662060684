.HitList{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
    flex-wrap:wrap;
    column-gap: 5%;
    row-gap: 70px;
    margin-bottom: 24px;
}
.LoaderContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media (max-width:610px){
    .HitList{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items:stretch;
    }

}
