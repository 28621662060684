.About{
    margin: 0 auto;
    width: 60%;
    
}
.About h1{
    text-align: center;
    margin-bottom: 0.7em;
}
.About p{
    text-align: justify;
    line-height: 1.5em;
    margin-bottom: 1em;
}
@media (max-width:610px){
    .About{
        width: 80%;
    
    }   
}